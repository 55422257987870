import React, { FC, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button as AntButton,
  Card as AntCard,
  Select as AntdSelect,
} from 'antd';

import LoadingContainer from '../LoadingContainer/LoadingContainer';
import compareByDemoAccess from '../../helpers/compare-by-demo-access';
import { ITariff, ITariffOptions, IThemes } from '../../interfaces/common';
import { APP_URL, DATA } from '../../constants/common-app.const';

import css from './KnowledgeCards.module.css';

interface IProps {
  themes: IThemes[];
  tariffs: ITariff[];
  tariff: ITariffOptions;
  isLoading: boolean;
  setTariff: Function;
  setPaymentData: Function;
  togglePaymentModal: Function;
}

const KnowledgeCards: FC<IProps> = ({
  themes,
  tariff,
  tariffs,
  setTariff,
  isLoading,
  setPaymentData,
  togglePaymentModal,
}) => {
  const history = useHistory();
  const [selectedTariff, setSelectedTariff] = useState<{
    [key: string]: string;
  }>({});

  return (
    <div className={css.SpecWrapper}>
      <LoadingContainer isLoading={isLoading}>
        {themes?.length && tariffs?.length ? (
          themes.sort(compareByDemoAccess).map((theme) => {
            const activeSubscriptions =
              new Date(
                theme.userSubscriptions[
                  theme.userSubscriptions.length - 1
                ]?.expireAt,
              ) > new Date();

            const activeInFutureSubscriptions =
              new Date(
                theme.userSubscriptions[
                  theme.userSubscriptions.length - 1
                ]?.startAt,
              ) > new Date();

            const expireAt =
              theme.userSubscriptions[theme.userSubscriptions.length - 1]
                ?.expireAt;

            const startAt =
              theme.userSubscriptions[theme.userSubscriptions.length - 1]
                ?.startAt;

            const isSubscriptionExpired = new Date(expireAt) < new Date();

            const prepareDate = (date: string) =>
              new Date(date).toLocaleString('ru', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
              });

            const expireDate = expireAt ? prepareDate(expireAt) : null;

            const startDate = startAt ? prepareDate(startAt) : null;

            const currentTariffs = theme?.tariffs?.length
              ? theme.tariffs
                  .sort((a, b) => (a.cost > b.cost ? 1 : -1))
                  .map(({ title, id }) => ({ label: title, value: id }))
              : tariffs;

            return (
              <AntCard className={css.SpecContainer} key={theme.id}>
                <p className={css.SpecName}>
                  {theme?.demoAccessEnabled && (!expireAt || isSubscriptionExpired) && (
                    <span className={css.DemoFlag}>ДЕМОВЕРСИЯ</span>
                  )}
                  {theme.title}
                </p>

                <div className={css.RateSelectWrapper}>
                  <AntdSelect
                    defaultValue={'default'}
                    className={css.RateSelect}
                    onChange={(value) => {
                      setTariff(
                        (state: ITariffOptions) =>
                          ({
                            ...state,
                            [theme.id]: currentTariffs.find(
                              (tariff) => tariff.value === value,
                            ),
                          } as ITariffOptions),
                      );
                      setSelectedTariff((prevState) => ({
                        ...prevState,
                        [theme.id]: value,
                      }));
                    }}
                  >
                    <AntdSelect.Option value={'default'} key={'default'}>
                      Выберите тариф
                    </AntdSelect.Option>
                    {currentTariffs.map((tariff) => (
                      <AntdSelect.Option
                        value={tariff.value}
                        key={tariff.value}
                      >
                        {tariff.label}
                      </AntdSelect.Option>
                    ))}
                  </AntdSelect>
                </div>

                <p className={css.ChooseRate}>
                  {activeInFutureSubscriptions && startAt
                    ? // Если у пользователя активна подписка и есть дата начала подписки
                      `Подписка начнёт действовать: ${startDate}`
                    : activeSubscriptions
                    ? // Если подписка пользователя активна и есть дата окончания подписки
                      `Ваша подписка истекает: ${expireDate}`
                    : theme.userSubscriptions.length &&
                      theme.userSubscriptions[
                        theme.userSubscriptions.length - 1
                      ]?.expireAt !== null
                    ? // Если у пользователя есть история подписок и последняя подписка истекла
                      `Ваша подписка истекла: ${expireDate}`
                    : null}
                </p>

                <AntButton
                  className={css.BuyBtn}
                  onClick={() => {
                    setPaymentData([theme.id, tariff[theme.id]?.value]);
                    togglePaymentModal();
                  }}
                  disabled={
                    tariff[theme.id]?.value !== selectedTariff[theme.id]
                  }
                >
                  {!theme.userSubscriptions.length ||
                  theme.userSubscriptions[theme.userSubscriptions.length - 1]
                    ?.expireAt === null
                    ? 'Купить'
                    : 'Продлить'}
                </AntButton>

                <AntButton
                  className={css.ButtonWrapper}
                  onClick={() => history.push(`${APP_URL.THEMES}/${theme.id}`)}
                >
                  Просмотр
                </AntButton>
              </AntCard>
            );
          })
        ) : (
          <div className={css.NoData}>{DATA.NOT_FOUND}</div>
        )}
      </LoadingContainer>
    </div>
  );
};

export default KnowledgeCards;
