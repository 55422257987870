import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { APP_URL } from '../../constants/common-app.const';
import { IQuestionGroup } from '../../interfaces/common';

import css from './OneTest.module.css';

const OneTest: FC<{ test: IQuestionGroup }> = ({ test }) => {
  const isFinished = test?.sessions.filter((el) => el.isFinished);
  const passingSession = test?.sessions.find((el) => !el.isFinished);
  const sessionId = passingSession?.id;
  const isDemo = test?.demoAccessEnabled === true;

  const subscriptions = test?.theme.userSubscriptions;

  const lastSubscription: { expireAt: string } =
    subscriptions[subscriptions.length - 1];

  const expireAt = lastSubscription?.expireAt;
  const isSubscriptionExpired = new Date(expireAt) < new Date();

  const secondaryLink = cx(css.Link, {
    [css.SecondaryLink]: !!passingSession,
  });

  const renderButton = () => {
    if (isDemo && (!expireAt || isSubscriptionExpired)) {
      return (
        <div className={css.ButtonWrapper}>
          <Link
            to={`${APP_URL.QUESTION_GROUP}/${test.id}`}
            className={css.Link}
          >
            Начать демо
          </Link>
        </div>
      );
    }

    if (!subscriptions.length) {
      return (
        <div className={css.ButtonWrapper}>
          <Link to={APP_URL.THEMES} className={css.Link}>
            Купить подписку
          </Link>
        </div>
      );
    }

    if (isSubscriptionExpired) {
      return (
        <div className={css.ButtonWrapper}>
          <Link to={APP_URL.THEMES} className={css.Link}>
            Продлить подписку
          </Link>
        </div>
      );
    }

    if (!passingSession && !isSubscriptionExpired && subscriptions.length > 0) {
      return (
        <div className={css.ButtonWrapper}>
          <Link
            to={`${APP_URL.QUESTION_GROUP}/${test.id}`}
            className={css.Link}
          >
            Начать
          </Link>
        </div>
      );
    }

    if (passingSession && !isSubscriptionExpired && subscriptions.length > 0) {
      return (
        <div className={css.ButtonWrapper}>
          <Link
            to={`${APP_URL.QUESTION_GROUP}/${test.id}?sessionId=${sessionId}`}
            className={secondaryLink}
          >
            Продолжить
          </Link>
          <Link
            to={`${APP_URL.QUESTION_GROUP}/${test.id}`}
            className={css.Link}
          >
            Пройти заново
          </Link>
        </div>
      );
    }
  };

  return (
    <div className={css.Container}>
      <div className={css.Wrapper}>
        <p
          className={css.Count}
        >{`Количество вопросов: ${test?.questionsCount}`}</p>
        {isDemo && (!expireAt || isSubscriptionExpired) && (
          <span className={css.DemoFlag}>ДЕМОВЕРСИЯ</span>
        )}
      </div>
      <p className={css.TestName}>{test.title}</p>
      <div className={css.ButtonForDoneTest}>
        <span
          className={css.Description}
        >{`Количество попыток: ${isFinished?.length}`}</span>
        {renderButton()}
      </div>
    </div>
  );
};

export default OneTest;
